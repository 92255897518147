import { Link } from 'gatsby';
import React from 'react';

export const Page404 = ()=>{

    return (
        <div style={{height:'90vh'}}>
        <div style={{display:'flex', flexDirection: 'column', height: '5rem', width: '25rem', justifyContent:'center', alignItems:'center', margin: '5rem auto'}}>
            <h1>Page Not Found</h1>
            <p>
                The page you are looking for is not here.
                I recently updated my site and havent finished the clean up.
            </p>
            <Link to="/">Go to home</Link>
        </div>
        </div>
    )
}

export default Page404;